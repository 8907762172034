import React, { useContext } from 'react'

const ThemeContext = React.createContext({
  /**
   * 页面封面样式
   */
  theme: 'normal',
  /**
   * 导航封面背景颜色
   */
  background: '',
  /**
   * 导航字体颜色
   */
  fontColor: '',
  /**
   *  选中色 只有pc端
   */
  activeColor: '',
})

export const ThemeContextProvider = ThemeContext.Provider

export const ThemeContextConsumer = ThemeContext.Consumer

export default () => {
  return useContext(ThemeContext)
}
