import { useEffect, useMemo, useState } from 'react'

const calcMenusIdxs = menu => {
  const pathname = window.location.pathname
  let res = []
  const findIdxs = (tree, paths) => {
    const lTmp = paths.slice()
    // 值无效 结束递归
    if (!(Array.isArray(tree) && tree.length)) {
      return
    }

    for (let i = 0; i < tree.length; i++) {
      const { children, to } = tree[i]
      lTmp.push(i)

      // 找到最终结果 直接结束循环
      if (to && pathname.includes(to)) {
        res = lTmp
        return true
      }

      if (Array.isArray(children)) {
        const flag = findIdxs(children, lTmp)
        // 找到了
        if (flag) {
          return true
        }
      }
      lTmp.pop()
    }
  }
  findIdxs(menu, [])
  return res
}

/**
 * 主要做的事讲树状结构每一项根据下标进行标记 祖先节点 作为 idx属性
 *
 * 顶层就是自己的下标，最后一级就是祖先节点的所有下标拼接
 *
 * 例如：
 * [{a: 1}, {a: 2, children: [{c: 3}]}]
 * => [{ a: 1, idx: '0' }, { a:2, idx: '1', children: [{ c:3, idx: '1-3' }] }]
 */
const markTree = treeList => {
  const fn = (tree, parentIdx) => {
    if (!tree) {
      return []
    }

    return tree.map((item, i) => {
      const { children } = item
      const idx = parentIdx ? `${parentIdx}-${i}` : `${i}`
      if (Array.isArray(children)) {
        return {
          ...item,
          idx,
          children: fn(children, idx),
        }
      }
      return {
        ...item,
        idx,
      }
    })
  }

  return fn(treeList, '')
}

const useActiveMenuIdxs = menu => {
  /**
   * 游标 用来临时选中样式
   */
  const [activeIdxTmp, setActiveIdxTmp] = useState(-1)
  const [activeIdx, setActiveIdx] = useState({
    /**
     * 一级导航
     */
    firstIdx: 0,
    /**
     * 二级导航
     */
    secondIdx: -1,
    /**
     * 三级导航
     */
    thirdIdx: -1,
  })

  // 每一个都打上index标记 作为 idx
  const menuFormatted = useMemo(() => markTree(menu), [menu])

  useEffect(() => {
    const [firstIdx = '', secondIdx = '', thirdIdx = ''] = calcMenusIdxs(
      menuFormatted
    )
    setActiveIdx({
      firstIdx,
      secondIdx,
      thirdIdx,
    })
  }, [menuFormatted])

  return {
    menuFormatted,
    activeIdxTmp: activeIdxTmp.toString(),
    firstIdx:
      activeIdxTmp >= 0 // 选中项有值，就直接用，否则就用选中的
        ? activeIdxTmp.toString() || activeIdx.firstIdx.toString()
        : activeIdx.firstIdx.toString(),
    secondIdx: `${activeIdx.firstIdx}-${activeIdx.secondIdx}`,
    thirdIdx: `${activeIdx.firstIdx}-${activeIdx.secondIdx}-${activeIdx.thirdIdx}`,
    setActiveIdxTmp,
  }
}

export default useActiveMenuIdxs
