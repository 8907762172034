import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { IoIosClose } from 'react-icons/io'
import './style.scss'

import BurgerButton from './BurgerButton'
import MenuGroup from './MenuGroup'

/**
 * 重置页面的高度，让页面不能滚动
 */
const resetBody = menuVisible => {
  const documentStyleBak = document.body.style
  if (menuVisible) {
    document.body.style.height = '100vh'
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style = documentStyleBak
  }
}

/**
 * 重制百度联系我们的 z-index
 */
const resetBaiduHM = menuVisible => {
  const dom = document.body.querySelector('#newBridge')
  if (dom) {
    const cacheZIndex = dom.style.zIndex
    if (menuVisible) {
      console.log()
      dom.style.zIndex = 1
    } else {
      dom.style.zIndex = cacheZIndex
    }
  }
}

const HeaderMobileNew = () => {
  const [menuVisible, setMenuVisible] = useState(false)

  useEffect(() => {
    resetBody(menuVisible)
    resetBaiduHM(menuVisible)
  }, [menuVisible])

  return (
    <div className="mobile-menu">
      <BurgerButton
        close={menuVisible}
        onClick={() => {
          setMenuVisible(!menuVisible)
        }}
      />
      {menuVisible && (
        <div className="navbar-menu-panel">
          <div className="panel-content">
            <div className="panel-close">
              <Link to="/" className="navbar-logo">
                <i className="iconfont z-a-138-logo" />
              </Link>
              <IoIosClose
                size={26}
                onClick={() => {
                  setMenuVisible(!menuVisible)
                }}
              />
            </div>
            <MenuGroup />
          </div>
        </div>
      )}
    </div>
  )
}

export default HeaderMobileNew
