import React from 'react'
import cx from 'classnames'
import './index.scss'

const ProductSection2 = ({
  className,
  title,
  subTitle,
  image,
  type = 'row',
  subTitleArray,
  id,
}) => {
  const prefix = 'product-section2'

  return (
    <div id={id}>
      <section
        className={cx(
          prefix,
          `${prefix}-decorator`,
          `${prefix}-${type}`,
          className
        )}
      >
        <div className="info">
          <div className="title">{title}</div>
          {subTitle && <div className="sub-title">{subTitle}</div>}
          {subTitleArray && (
            <div className="sub-title">
              {subTitleArray.map(item => (
                <div>{item}</div>
              ))}
            </div>
          )}
        </div>
        <div className="image">{image}</div>
      </section>
    </div>
  )
}

export default ProductSection2
