const ShangHaiDefaultConfig = {
  company: '© 再惠（上海）网络科技有限公司',
  icp: '沪ICP备19035351号',
  pageTitle: '本地生活领域的全栈电商解决方案 | 再惠网络科技',
  address: '上海市普陀区中江路118弄22号海亮大厦A座11楼',
}

/**
 * 从给定的主机名中提取主域名。
 * @param {string} hostname - 要从中提取主域名的主机名。
 * @returns {string} 从主机名中提取的主域名。
 */
function getMainDomain(hostname) {
  const parts = hostname.split('.').reverse()
  if (parts.length > 2) {
    // 检查是否为像 .co.uk 这样的特殊域名
    if (parts[1].length <= 3) {
      return parts[2] + '.' + parts[1] + '.' + parts[0]
    }
  }
  return parts[1] + '.' + parts[0]
}

export function getBeiAn() {
  // https://github.com/gatsbyjs/gatsby/issues/309
  const hostname =
    (typeof window !== 'undefined' &&
      typeof window.location !== 'undefined' &&
      window.location.hostname) ||
    ''
  const mainDomain = getMainDomain(hostname)

  switch (mainDomain) {
    case 'zaihui.com.cn':
      return {
        ...ShangHaiDefaultConfig,
        text: '沪公网安备31010702007856号',
        code: '31010702007856',
        needLogo: true,
      }
    case 'kezaihui.com':
      return {
        ...ShangHaiDefaultConfig,
        text: '沪公网安备31010702007854号',
        code: '31010702007854',
        needLogo: true,
      }
    case 'zaihuiyun.com':
      return {
        ...ShangHaiDefaultConfig,
        text: '沪公网安备31010702007869号',
        code: '31010702007869',
        needLogo: true,
      }
    case 'zaihuiba.com':
    case 'zaihui8.com':
    case 'wozaihui.com':
      return {
        ...ShangHaiDefaultConfig,
        address: '',
      }
    case 'ywyz.tv':
      return {
        ...ShangHaiDefaultConfig,
        company: '© 天津再惠电子商务有限公司',
        icp: '津ICP备2022008863号',
        address: '',
      }
    // 新域名用再惠的备案
    default:
      return {
        ...ShangHaiDefaultConfig,
        address: '',
      }
  }
}
