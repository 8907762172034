import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

function hexToRGB(hex = '', alpha) {
  let candidate = hex
  if (hex.startsWith('#')) {
    candidate = candidate.slice(1)
  }

  let r = 0
  let g = 0
  let b = 0
  if (candidate.length > 3) {
    r = parseInt(candidate.slice(0, 2), 16)
    g = parseInt(candidate.slice(2, 4), 16)
    b = parseInt(candidate.slice(4, 6), 16)
  } else {
    r = parseInt(candidate.slice(0, 1), 16)
    g = parseInt(candidate.slice(1, 2), 16)
    b = parseInt(candidate.slice(2, 3), 16)
  }

  return [r, g, b]
}

const ItemCard = ({ data, active }) => {
  const { tags, tagColor, desc, to, name, icon } = data
  const rgb = hexToRGB(tagColor).join(',')

  return (
    <Link to={to} style={{ display: 'flex', height: '100%' }}>
      <div
        className="item-card"
        style={{
          '--hover-color': `rgba(${rgb}, .1)`,
          backgroundColor: active ? `rgba(${rgb}, .1)` : '',
        }}
      >
        <div className="icon-wrapper" style={{ backgroundColor: tagColor }}>
          <img src={icon} className="icon" />
        </div>
        <div className="item-card-content">
          <div className="title">{name}</div>
          {tags && tags.length ? (
            <div className="tag-wrapper">
              {tags.map(item => {
                return (
                  <div
                    className="tag"
                    key={item}
                    style={{
                      color: tagColor,
                      borderColor: `rgba(${rgb}, .2)`,
                    }}
                  >
                    {item}
                  </div>
                )
              })}
            </div>
          ) : null}

          {desc ? <div className="desc">{desc}</div> : null}
        </div>
      </div>
    </Link>
  )
}

export default ItemCard
