import gongyinxlian from './icon/gongyinxlian.svg'
import jiaoyi from './icon/jiaoyi.svg'
import kaidian from './icon/kaidian.svg'
import neirong from './icon/neirong.svg'
import xiaoshou from './icon/xiaoshou.svg'
import pay from './icon/pay.svg'
import renshi from './icon/renshi.svg'
import siyu from './icon/siyu.svg'
import xundian from './icon/xundian.svg'
import yingxiao from './icon/yingxiao.svg'
import gongsi from './icon/gongsi.svg'
import join from './icon/join.svg'
import lianxi from './icon/lianxi.svg'
import life from './icon/life.svg'

/**
 * 获取默认的三级
 */
const genDefaultThirdRouter = (name, to) => ({
  /**
   * 中文名称
   */
  name,
  /**
   * 跳转路由
   */
  to,
  /**
   * 描述
   */
  desc: '',
  /**
   * 标签文案
   */
  tags: [],
  /**
   * 标签颜色
   */
  tagColor: '',
  /**
   * 字体
   */
  icon: '',
})

export const menuNew = [
  {
    name: '产品介绍', // 页面名
    to: '', // 页面跳转
    children: [
      {
        groupName: '营销平台',
        children: [
          {
            ...genDefaultThirdRouter(
              '私域解决方案',
              '/systemProduct/privateDomain'
            ),
            desc: '一体化、定制化私域流量解决方案',
            tags: ['会员管理', '社群管家', '收银支付', '多场景多业态'],
            tagColor: '#f8b137',
            icon: siyu,
          },
          {
            ...genDefaultThirdRouter(
              '交易平台解决方案',
              '/systemProduct/tradePlatform'
            ),
            desc: '为商家提供美团外卖、点评、饿了么、口碑等交易平台解决方案',
            tags: ['店铺体检', '智能策略推荐', '自动化操作'],
            tagColor: '#7458d2',
            icon: jiaoyi,
          },
          {
            ...genDefaultThirdRouter(
              '内容平台解决方案',
              '/systemProduct/contentPlatform'
            ),
            desc:
              '为商户提供抖音、快手、小红书、B 站等新媒体和内容媒体解决方案',
            tags: ['达人匹配', '运营策略', '赋能运营'],
            tagColor: '#3f6cf8',
            icon: neirong,
          },
          {
            ...genDefaultThirdRouter(
              '惠生活权益解决方案',
              '/systemProduct/huiLifePlatform'
            ),
            desc: '通过系统能力，为商户提供更多渠道的引流并转换',
            tags: ['权益聚合', '引流到店', '系统闭环'],
            tagColor: '#00B386',
            icon: jiaoyi,
          },
          {
            ...genDefaultThirdRouter(
              '精准投放解决方案',
              '/systemProduct/targetedDeliverySolution'
            ),
            desc: '综合主流平台，提供全链路营销解决方案',
            tags: ['平台资源', '数字营销', '广告投放', '多渠道推广'],
            tagColor: '#E52B58',
            icon: jiaoyi,
          },
        ],
      },
    ],
  },
  {
    name: '解决方案',
    to: '',
    children: [
      {
        groupName: '',
        to: '',
        children: [
          {
            ...genDefaultThirdRouter('开店', '/products/openshop'),
            tagColor: '#1671d8',
            desc: '从零到一，手把手解决开店难题',
            icon: kaidian,
          },
          {
            ...genDefaultThirdRouter('营销', '/products/marketing'),
            tagColor: '#ffc210',
            desc: '共建本地生态，共赢行业未来',
            icon: yingxiao,
          },
          {
            ...genDefaultThirdRouter('销售', '/products/sale'),
            tagColor: '#ed3737',
            desc: '全渠道赋能，主导本地生活新时代',
            icon: xiaoshou,
          },
          {
            ...genDefaultThirdRouter('支付', '/products/payment'),
            tagColor: '#dc5d3d',
            desc: '全渠道支付体系，助力商家提效',
            icon: pay,
          },
        ],
      },
    ],
  },
  {
    name: '媒体报道',
    to: '/news',
  },
  {
    name: '了解我们',
    children: [
      {
        groupName: '',
        to: '',
        children: [
          {
            ...genDefaultThirdRouter('公司介绍', '/about'),
            desc: '致力于让每个老板都能轻松开店',
            tags: [],
            icon: gongsi,
            tagColor: '#dc5d3d',
          },
          {
            ...genDefaultThirdRouter('加入我们', '/join_us'),
            desc: '再惠欢迎你的加入',
            tags: [],
            icon: join,
            tagColor: '#ee4949',
          },
          {
            ...genDefaultThirdRouter('联系我们', '/contact_us'),
            desc: 'marketing@kezaihui.com',
            tags: [],
            icon: lianxi,
            tagColor: '#4ba154',
          },
        ],
      },
    ],
  },
]
