import React, { useMemo } from 'react'
import cx from 'classnames'
import './style.scss'

const TitleWrapper = ({
  children,
  title,
  subTitle,
  desc,
  homeSeparatorPos = 'bottom',
  withSeparator = true,
  type = 'center',
  titleClassName,
  subTitleClassName,
}) => {
  const listDom = useMemo(() => {
    if (Array.isArray(desc)) {
      return (
        <ul className="list-wrapper">
          {desc.map((item, idx) => (
            <li className="list-item" key={idx}>
              <i className="iconfont z-a-024-check check-mark" />
              <p className="desc-text">{item}</p>
            </li>
          ))}
        </ul>
      )
    }
    return null
  }, [desc])

  const prefix = 'custom-title-wrapper'

  return (
    <div
      ref={el => {
        const observer = new IntersectionObserver(
          e => {
            const [obj] = e

            if (obj.isIntersecting) {
              el.className = `${el.className} show`
              observer.unobserve(el)
              observer.disconnect()
            }
          },
          { threshold: 0.5 }
        )

        if (el) {
          observer.observe(el)
        }
      }}
      className={cx(prefix, `${prefix}-${type}`)}
    >
      {withSeparator ? (
        <div className={cx('home-separator', homeSeparatorPos)} />
      ) : null}
      <h2 className={cx('title head1', titleClassName)}>{title}</h2>
      {subTitle ? (
        <div className="sub-title">
          <div className={cx('head1-sub', subTitleClassName)}>{subTitle}</div>
        </div>
      ) : null}
      {listDom}
      {children}
    </div>
  )
}

export default TitleWrapper
