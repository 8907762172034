import React from 'react'
import cx from 'classnames'

import TitleWrapper from '../../components/TitleWrapper'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { getBeiAn } from '@homepages/shared/utils/getBeiAn'
import Record from '../../components/Record'

import './index.scss'

const BEIAN_LINK = 'https://beian.miit.gov.cn/'

const HomeSection3 = () => {
  const {
    zhVideoQrCode,
    zhQrCode,
    elmLogo,
    meituanLogo,
    beianLogo,
  } = useStaticQuery(graphql`
    query {
      zhVideoQrCode: file(relativePath: { eq: "zh-video-qr-code.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zhQrCode: file(relativePath: { eq: "zaihui-qrcode.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      elmLogo: file(relativePath: { eq: "BlogRoll/elm.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meituanLogo: file(relativePath: { eq: "BlogRoll/meituan.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beianLogo: file(relativePath: { eq: "beian-logo.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { company, icp, address, needLogo, text, code } = getBeiAn()

  const prefix = 'home-section3'
  return (
    <section className={cx('group', prefix, `${prefix}-decorator`)}>
      <div className={cx('grid', `${prefix}-head`)}>
        <div className="grid">
          <TitleWrapper
            titleClassName={`h-1 ${prefix}-title`}
            title="即刻点击，进一步了解再惠全方位解决方案"
            withSeparator={false}
          />
        </div>
        <div className={cx(`${prefix}-btn-box`)}>
          <Link className="btn btn-size-1" to={'/about'}>
            了解再惠 <i className="iconfont z-a-012-right" />
          </Link>
        </div>
        <div className={cx('grid', `${prefix}-qr-code-box`)}>
          <div className="qr-code-wrapper">
            <div className="qr-code">
              <Img fluid={zhQrCode.childImageSharp.fluid} alt="再惠公众号" />
            </div>
            <p>再惠公众号</p>
          </div>
          <div className="qr-code-wrapper">
            <div className="qr-code">
              <Img
                fluid={zhVideoQrCode.childImageSharp.fluid}
                alt="再惠视频号"
              />
            </div>
            <p>再惠视频号</p>
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <div className="blog-roll">
          <p>友情链接：</p>
          <div className="platform-wrapper">
            <a
              className="platform"
              href="https://rms.meituan.com/?lch=mhq6w4bl9ogmspYm2gHXSn2rw"
            >
              <div className="logo-image">
                <Img fluid={meituanLogo.childImageSharp.fluid} />
              </div>
              美团•餐饮系统
            </a>
            <a className="platform" href="https://www.ele.me/">
              <div className="logo-image">
                <Img fluid={elmLogo.childImageSharp.fluid} />
              </div>
              饿了么
            </a>
          </div>
        </div>
        <div className="home-contact-wrapper pc">
          <div className="pc-col">
            <span className="pc-col-item">
              客服咨询电话<i className="contact">021-60523213</i>
            </span>
            <span>{company}</span>
          </div>
          <Record
            needRender={needLogo}
            className="pc-col"
            imageClassName="logo-image"
            fluid={beianLogo.childImageSharp.fluid}
            text={text}
            code={code}
          />
          <a className="pc-col" href={BEIAN_LINK} target="_blank">
            {icp}
          </a>
          {address && <span className="pc-col">{address}</span>}
        </div>
        <p className="home-contact-wrapper mobile">
          <span>
            客服咨询电话
            <a href="tel:021-60523213" className="contact">
              021-60523213
            </a>
          </span>
          <span>{company}</span>
          <Record
            needRender={needLogo}
            className="pc-col"
            imageClassName="logo-image"
            fluid={beianLogo.childImageSharp.fluid}
            text={text}
            code={code}
          />
          <a href={BEIAN_LINK}>{icp}</a>
          {address && <span>{address}</span>}
        </p>
      </div>
    </section>
  )
}
export default HomeSection3
