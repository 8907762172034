import React from 'react'
import PropTypes from 'prop-types'

import Header from '../Header'
import logoWhite from '@homepages/shared/images/zaihui-logo-white.png'
import logo from '@homepages/shared/images/zaihui-logo.png'
import { colorTheme, colorWhite } from '@homepages/shared/styles/variables'
import SEO from '../seo'
import menuData, { menuNew } from './menuData'

import './style.scss'
import { ThemeContextProvider } from '../../hooks/useTheme'
import { getBeiAn } from '@homepages/shared/utils/getBeiAn'

const fontColorMapHoverColor = {
  [colorTheme]: '#9b3800',
  // https://rgbacolorpicker.com/rgba-to-hex
  default: '#ffffffb3',
}

const Layout = ({
  headerTheme,
  headerBackground,
  children,
  className,
  fontColor,
  activeColor,
}) => {
  const { pageTitle } = getBeiAn()

  return (
    <ThemeContextProvider
      value={{
        activeColor: activeColor || fontColor,
        theme: headerTheme,
        background: headerBackground,
        fontColor: fontColor,
        // https://stackoverflow.com/a/59906804/9672709
        // css var 不支持rgba函数
        hoverColor: fontColorMapHoverColor[fontColor || 'default'],
      }}
    >
      <div className="layout-wrpper">
        <SEO
          title={pageTitle}
          description="再惠为全国本地生活商户提供一站式电商服务，包括在线开店、店铺设计、 市场营销、经营数据监控及库存管理、客户关系管理等，同时提供支付、POS、短视频等增值服务，与抖音、B站等众多社交媒体合作，也对接美团点评、口碑等交易平台，同时有丰富的私域流量解决方案，为广大线下中腰部商家提供了在线经营的便利。"
          keywords="再惠,再惠营销云,再惠分析云,全域营销云,数据分析云,火星外卖营销云,一碗短视频营销云,抖音解决方案,快手解决方案,再惠玩点营销云,惠外卖,商城,惠营销"
        />
        <Header
          theme={headerTheme}
          background={headerBackground}
          menu={menuData}
          menuNew={menuNew}
          logo={headerTheme === 'white' ? logoWhite : logo}
          fontColor={fontColor ? fontColor : ''}
        />
        <div className="layout-content">
          <main className={className}>{children}</main>
        </div>
      </div>
    </ThemeContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerTheme: PropTypes.oneOf(['normal', 'white']),
}

export default Layout
