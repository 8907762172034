import React, { useMemo } from 'react'
import cls from 'classnames'
import './style.scss'
import ItemCard from '../ItemCard'
import { useSelectedContext } from '../../hooks/SelectedHook'

/**
 * 打平连个数组，然后没有的补充 null
 * @example
 * crossMergeArr([1,2,3], [4,5,6]) // [[1,4],[2,5],[3,6]]
 * crossMergeArr([1,2], [4,5,6]) // [[[1,4],[2,5],[null,6]]
 */
const crossMergeArr = (...args) => {
  const crossLen = args.length
  const res = []
  let j = 0
  while (true) {
    let resTmp = []
    for (let i = 0; i < crossLen; i++) {
      resTmp = args.map(item => item[j] || null) // 没有的用null替代
    }
    if (!resTmp.some(e => !!e)) {
      break
    }
    res.push(resTmp)
    j++
  }
  return res.slice()
}

const HeaderGroupItem = ({ menus = [] }) => {
  // NOTICE: 这里的 menus 是我们格式化之后的数据 回存在idx

  const { thirdIdx } = useSelectedContext()

  const splitMenus = useMemo(() => {
    // 这里是将 menus group 以下级别的数据格式化成 交替的形式，但是如果出现某一数组数据过多，其他数据就补充null
    // 补充 null 是因为 不可能跨分组展示
    return crossMergeArr(...(menus.map(item => item.children) || []))
  }, [menus])

  return (
    <div
      className={cls(['navbar-menu-dropdown'])}
      ref={ref => {
        if (ref) {
          // 自动调整位置
          const { left, right } = ref.getBoundingClientRect()
          if (left < 10) {
            ref.style.left = `${Math.abs(left) + 10}px`
          } else if (window.innerWidth - right < 10) {
            ref.style.left = `${-Math.abs(window.innerWidth - right) - 10}px`
          }
        }
      }}
    >
      <div className="secondary-group-wrapper">
        {menus.map(({ groupName }, idx) => {
          return (
            <div key={idx} className="secondary-group">
              {groupName ? <div className="group-name">{groupName}</div> : null}
            </div>
          )
        })}
      </div>
      <div className="third-group">
        {splitMenus.map((chunks, idx) => {
          return (
            <div className="third-group-chunk" key={idx}>
              {chunks.map((menu, menuIdx) => {
                // NOTICE: 保留用来 调试
                // console.log(thirdIdx, menu.idx)
                return (
                  <div
                    className="group-item-wrapper"
                    key={menu ? menu.to : menuIdx}
                  >
                    {menu ? (
                      <ItemCard
                        active={thirdIdx === menu.idx}
                        key={menu.to}
                        data={menu}
                      />
                    ) : null}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HeaderGroupItem
