import React, { useContext } from 'react'
import useActiveMenuIdxs from '../../../hooks/useActiveMenuIdxs'

export const HeaderSelectedContext = React.createContext({})

export const useSelectedContext = () => useContext(HeaderSelectedContext)

/**
 * 共享 导航栏 默认选中逻辑
 */
export const HeaderSelectedProvider = props => {
  const {
    menuFormatted,
    activeIdxTmp,
    firstIdx,
    thirdIdx,
    setActiveIdxTmp,
  } = useActiveMenuIdxs(props.menu)

  return (
    <HeaderSelectedContext.Provider
      value={{
        menuFormatted,
        defaultIdx: '0', // 默认选中项
        activeIdxTmp, // 激活项，第一级，不一定是选中项
        firstIdx, // 导航栏第一级选中
        thirdIdx, // 导航栏第三级选中
        setActiveIdxTmp, // 设置 第一级待选中
      }}
    >
      {props.children}
    </HeaderSelectedContext.Provider>
  )
}
