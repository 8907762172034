export const cssMediaQuery = (queryString, cb) => {
  // max-width: 766.98px
  // 参考: styles/index.scss media query
  const mediaData = window.matchMedia(queryString)
  cb && cb(mediaData)
  mediaData.addListener((res = {}) => {
    cb && cb(res)
  })
}

export function debounce(func, delay) {
  let timer
  return function(...args) {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
