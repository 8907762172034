import React from 'react'
import Img from 'gatsby-image'
import './style.scss'

const Record = ({
  needRender = false,
  className,
  imageClassName,
  fluid,
  code = '31010702007854',
  text = '沪公网安备31010702007854',
}) =>
  needRender ? (
    <span className={className}>
      <a
        className="flex-wrapper"
        href={`https://beian.mps.gov.cn/#/query/webSearch?code=${code}`}
        rel="noreferrer"
        target="_blank"
      >
        <Img className={imageClassName} fluid={fluid} />
        <span>{text}</span>
      </a>
    </span>
  ) : null

export default Record
