import React from 'react'
import { Link } from 'gatsby'
import { IoIosArrowDown } from 'react-icons/io'
import './style.scss'
import HeaderGroupItem from '../component/HeaderGroupItem'
import { useSelectedContext } from '../hooks/SelectedHook'
import useTheme from '../../../hooks/useTheme'

const HeaderPCNew = () => {
  const { menuFormatted: menu = [], firstIdx } = useSelectedContext()
  const { fontColor, activeColor, hoverColor } = useTheme()

  return (
    <div className="navbar-menu">
      <div className="item-wrapper">
        {menu.map(({ name, to, children, idx }) => {
          // NOTICE: 保留用来 调试
          // console.log(firstIdx, idx)
          return (
            <Link
              className="navbar-menu-group"
              style={{
                '--hover-color': hoverColor,
                ...(firstIdx === idx
                  ? { color: activeColor }
                  : { color: fontColor }),
              }}
              key={name}
              to={to}
            >
              {name}
              {!to ? (
                <IoIosArrowDown
                  style={{ marginLeft: 4, position: 'relative', top: '2px' }}
                />
              ) : null}
              {children ? <HeaderGroupItem menus={children} /> : null}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default HeaderPCNew
