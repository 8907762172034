export const colorWhite = '#FFFFFF'
export const colorLightRed = '#dc5d3d'
export const colorBlack = '#000000'
export const colorPrimary = '#E95E3D'
export const colorBg = '#F8F8F8'
export const colorYellow = '#FDC82F'
export const colorGreen = '#00BB86'
export const colorOrange = '#F87F43'
export const colorGold = '#FEAF1D'
export const colorBlue = '#1671D8'
export const colorDeepBlue = '#19253C'
export const colorPurple = '#4B4E7D'
export const colorRed = '#D14E43'
export const colorTheme = '#DC5D3D'
export const fontColorBlack = colorBlack
export const fontColorDeep = '#1E1E1E'
export const fontColorDark = '#444444'
export const fontColorNormal = '#666666'
export const fontColorLight = '#999999'
export const fontColorWhite = colorWhite
export const fontSize1 = '32px'
export const fontSize2 = '28px'
export const fontSize3 = '16px'
export const fontSize4 = '14px'
export const fontSize5 = '13px'
export const fontSize6 = '12px'
export const fontWeightBold = 700
export const fontWeightMedium = 500
export const fontWeightNormal = 400
export const fontWeightLight = 200
