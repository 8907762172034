import React from 'react'
import cx from 'classnames'
import './index.scss'

import TitleWrapper from '../../components/TitleWrapper'
import { Fragment } from 'react'

const ProductSection1 = ({
  className,
  background,
  title,
  subTitle,
  extra,
  subTitleArray,
}) => {
  const prefix = 'product-section1'
  return (
    <section className={cx(prefix, className)} style={{ background }}>
      <TitleWrapper
        title={title}
        subTitle={
          <Fragment>
            {subTitle && <div className="sub-title">{subTitle}</div>}
            {subTitleArray && (
              <div className="sub-title">
                {subTitleArray.map(item => (
                  <div>{item}</div>
                ))}
              </div>
            )}
            {extra && <div className="extra">{extra}</div>}
          </Fragment>
        }
        withSeparator={false}
        titleClassName="h-1"
        subTitleClassName="head1-sub s-2"
      />
    </section>
  )
}

export default ProductSection1
