import React from 'react'
import './empty.scss'

const Empty = () => (
  <div className="cust-empty">
    <i className="iconfont z-a-138-logo" style={{ fontSize: '48px' }} />
  </div>
)

export default Empty
