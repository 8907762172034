import React from 'react'
import cx from 'classnames'
import './style.scss'
// 样式在 index.js 里

const BurgerButton = ({ close = false, color, onClick }) => (
  <div
    className={cx('navbar-burger', close && 'navbar-burger-close')}
    onClick={onClick}
  >
    <span style={{ background: color }} aria-hidden="true"></span>
    <span style={{ background: color }} aria-hidden="true"></span>
    <span style={{ background: color }} aria-hidden="true"></span>
  </div>
)

export default BurgerButton
