import React, { useContext, useMemo } from 'react'
import ItemCard from '../../component/ItemCard'
import './style.scss'
import LinkDiv from '../LinkDiv'
import Empty from './empty'
import { useSelectedContext } from '../../hooks/SelectedHook'

const MenuGroup = () => {
  const {
    menuFormatted: menu,
    defaultIdx,
    activeIdxTmp,
    firstIdx,
    thirdIdx,
    setActiveIdxTmp,
  } = useSelectedContext()

  /**
   * 判定是否能匹配项
   */
  const validActiveIdx = useMemo(() => {
    // NOTICE: firstIdx activeIdxTmp 是字符串
    return (
      (firstIdx != '' && Number(firstIdx) >= 0) ||
      (activeIdxTmp !== '' && Number(activeIdxTmp) >= 0)
    )
  }, [firstIdx, activeIdxTmp])

  return (
    <div className="mobile-menu-group">
      <div className="nav-bar">
        {menu.map(({ name, to }, idx) => (
          <LinkDiv
            key={name}
            to={to}
            name={name}
            className={
              // 判定默认填充字段
              // [firstIdx, activeIdxTmp] 表示 激活选中的tab，待激活的tab
              //   注：上一行会存在同时多个tab一起选中
              // [defaultIdx] 表示路由匹配不到的默认展示的tab
              // `idx` 需要转成字符串
              (validActiveIdx
                ? [firstIdx, activeIdxTmp]
                : [defaultIdx]
              ).includes(`${idx}`)
                ? 'active'
                : ''
            }
            onClick={() => setActiveIdxTmp(idx)}
          />
        ))}
      </div>
      <div className="mobile-menu-secondary-animation">
        {menu.map(({ name, children }, idx) => (
          <div
            className={`mobile-menu-secondary-wrapper ${
              // 判定 是否需要填充默认字段
              // 是 (activeIdxTmp >= 0 ? activeIdxTmp === idx : firstIdx === idx) 判定是用哪个字段判定展示哪个tab的 二三级选择面板
              // 否 (defaultIdx >= 0) 表示 展示默认面板
              // NOTICE: activeIdxTmp firstIdx 是字符串 defaultIdx 不能用三等符
              (validActiveIdx
              ? activeIdxTmp >= 0
                ? activeIdxTmp == idx
                : firstIdx == idx
              : defaultIdx == idx)
                ? 'active'
                : ''
            }`}
            key={name}
          >
            {Array.isArray(children) && children.length ? (
              children.map(e => (
                <React.Fragment key={e.groupName}>
                  <div key={e.groupName} className="mobile-group-name-wrapper">
                    {e.groupName ? (
                      <div className="group-name">{e.groupName}</div>
                    ) : null}
                  </div>
                  {Array.isArray(e.children) ? (
                    <div className="group-items">
                      {e.children
                        ? e.children.map(card => {
                            return (
                              <ItemCard
                                active={thirdIdx === card.idx}
                                key={card.to}
                                data={card}
                              />
                            )
                          })
                        : null}
                    </div>
                  ) : null}
                </React.Fragment>
              ))
            ) : (
              <Empty />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default MenuGroup
