import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import './style.scss'

const LinkDiv = ({ name, to, onClick, className }) => {
  const Content = useMemo(() => {
    return <div className="title">{name}</div>
  }, [name])

  if (to) {
    return (
      <Link
        className={`${className} mobile-nav-title`}
        to={to}
        onClick={onClick}
      >
        {Content}
      </Link>
    )
  }

  return (
    <div className={`${className} mobile-nav-title`} onClick={onClick}>
      {Content}
    </div>
  )
}

export default LinkDiv
